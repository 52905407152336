.chatbox {
  font-family: 'azo-sans-web';
  background-color: #393d40;
  border-radius: 11px;
  width: 100%;
  margin: 0;
}
.chatbox p {
  padding: 0 12px;
  position: relative;
}

.chatbox.reply {
  margin: 0;
}
.chatbox.reply p {
  margin: 12px 0;
}

.chatbox.readOnly {
  background-color: transparent;
}
.chatbox.readOnly p {
  padding: 0;
  margin: 0;
}
